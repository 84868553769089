import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/4A/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/4A/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/4A/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/4A/4.jpg';








// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';
const ClassAssembly4A2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_url}/home/events-activities/class-assembly/4A/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/class-assembly/4A/2.webp`;
const p3 = `${config.base_image_url}/home/events-activities/class-assembly/4A/3.webp`;
const p4 = `${config.base_image_url}/home/events-activities/class-assembly/4A/4.webp`;



  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    }
    // {
    //     src: p5,
    //     source: p5,
    //     rows: 1.5,
    //     cols: 2,
    //   },
     

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Friendship day
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4A Date: 8 & 9 August 2024

        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Friends are the charming gardeners who make our souls blossom"

<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 4A presented their class assembly on the theme of ‘Friendship Day’ on Thursday 8 August and Friday, 9 August 2024. The assembly began with a thought-provoking message that emphasized the importance of friendship in our lives. The speakers, with confidence and clarity, shared insights on the significance and history of Friendship Day, tracing its origins and explaining how it has evolved over time.
        <br></br>
        Following this insightful introduction, the students brought the theme of friendship to life through a delightful and harmonious song that captured the essence of companionship and the joy of having close friends. This was followed by an energetic and well-coordinated dance performance that radiated enthusiasm and joy. The dancers moved in perfect sync, their expressions and movements vividly portraying the spirit of togetherness and camaraderie.
        <br></br>
        The assembly concluded with a heart-warming message, encouraging everyone to cherish and nurture the beautiful bonds they share with their wonderful friends. Both the performers and the audience thoroughly enjoyed the assembly.
        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “True Friends are like Diamonds. 
   <br></br>
   Bright, Beautiful, Valuable and always in Style.”
   <br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4A2024;